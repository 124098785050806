@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}
.body{
    line-height: 1.5;
    margin-top: 13%;
}
.boat-card-wrapper{
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 10%;
}
.boat-details{
  margin-bottom: 15%;
}
img{
    width: 100%;
    display: block;
}
.boat-img-display{
    overflow: hidden;
}
.boat-img-showcase{
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
}
.boat-img-showcase img{
    min-width: 100%;
    margin-top: 10%;
}
.boat-img-select{
    display: flex;
}
.boat-img-item{
    margin: 0.3rem;
}
.boat-img-item:nth-child(1),
.boat-img-item:nth-child(2),
.boat-img-item:nth-child(3){
    margin-right: 0;
}
.boat-img-item:hover{
    opacity: 0.8;
}
.boat-product-content{
    padding: 2rem 1rem;
}
.boat-product-title{
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    color: #12263a;
    margin: 1rem 0;
}
.boat-product-title::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 80px;
    background: #12263a;
}
.boat-product-link{
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    background: hsl(172, 71%, 25%);
    color: #fff;
    padding: 0 0.3rem;
    transition: all 0.5s ease;
}
.boat-product-link:hover{
    opacity: 0.9;
}
.boat-product-rating{
    color: #ffc107;
}
.boat-product-rating span{
    font-weight: 600;
    color: #252525;
}
.boat-product-price{
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 700;
}
.boat-product-price span{
    font-weight: 400;
}
.boat-last-price span{
    color: #f64749;
    text-decoration: line-through;
}
.boat-new-price span{
    color: hsl(172, 71%, 25%);
}
.boat-product-detail h2{
    text-transform: capitalize;
    color: #12263a;
    padding-bottom: 0.6rem;
}
.boat-product-detail p{
    font-size: 0.9rem;
    padding: 0.3rem;
    opacity: 0.8;
}
.boat-product-detail ul{
    margin: 1rem 0;
    font-size: 0.9rem;
}
.boat-product-detail ul li{
    margin: 0;
    list-style: none;
    background-size: 18px;
    padding-left: 1.7rem;
    margin: 0.4rem 0;
    font-weight: 600;
    opacity: 0.9;
}
.boat-product-detail ul li span{
    font-weight: 400;
}
.boat-purchase-info{
    margin: 1.5rem 0;
}
.boat-purchase-info input,
.boat-purchase-info .boat-btn{
    border: 1.5px solid #ddd;
    border-radius: 25px;
    text-align: center;
    padding: 0.45rem 0.8rem;
    outline: 0;
    margin-right: 0.2rem;
    margin-bottom: 1rem;
}
.boat-purchase-info input{
    width: 60px;
}
.boat-purchase-info .boat-btn{
    cursor: pointer;
    color: #fff;
}
.boat-purchase-info .boat-btn:first-of-type{
    background: hsl(172, 71%, 25%);
}
.boat-purchase-info .boat-btn:last-of-type{
    background: hsl(172, 71%, 25%);
}
.boat-purchase-info .boat-btn:hover{
    opacity: 0.9;
}
.boat-social-links{
    display: flex;
    align-items: center;
}
.boat-social-links a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    color: hsl(172, 71%, 25%);
    border: 1px solid hsl(172, 71%, 25%);
    margin: 0 0.2rem;
    border-radius: 50%;
    text-decoration: none;
    font-size: 0.8rem;
    transition: all 0.5s ease;
}
.boat-social-links a:hover{
    background: #000;
    border-color: transparent;
    color: #fff;
}

@media screen and (min-width: 992px){
    .boat-card{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }
    .boat-card-wrapper{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 35%;

    }
    .boat-product-imgs{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .boat-product-content{
        padding-top: 0;
        margin-top: 20%;

    }
}


/*-----------------------------------*\
  #style.css
\*-----------------------------------*/




/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

    /**
     * colors
     */
  
    --pale-purple-pantone: hsl(262, 63%, 92%);
    --medium-sea-green: hsl(152, 63%, 43%);
    --lavender-blush: hsl(336, 35%, 92%);
    --carolina-blue: hsl(172, 71%, 25%);
    --columbia-blue: hsl(204, 92%, 90%);
    --alice-blue-1: hsl(216, 38%, 95%);
    --alice-blue-2: hsl(216, 75%, 97%);
    --alice-blue-3: hsl(216, 38%, 95%);
    --alice-blue-4: hsl(172, 71%, 25%);
    --independence: hsl(0, 0%, 100%);
    --deep-cerise: hsl(329, 63%, 52%);
    --eerie-black: hsl(210, 11%, 15%);
    --space-cadet: hsl(240, 22%, 25%);
    --blue-jeans: hsl(204, 80%, 63%);
    --slate-blue: hsl(262, 60%, 57%);
    --beau-blue: hsl(208, 86%, 92%);
    --honey-dew: hsl(152, 48%, 89%);
    --mimi-pink: hsl(329, 63%, 90%);
    --red-salsa: hsl(0, 79%, 63%);
    --sapphire: hsl(211, 100%, 35%);
    --manatee: hsl(219, 14%, 60%);
    --white: hsl(0, 0%, 100%);
  
    --gradient: linear-gradient(to top, var(--alice-blue-2), var(--alice-blue-3));
  
    /**
     * typography
     */
  
    --ff-nunito: 'Nunito', sans-serif;
    --ff-open-sans: 'Open Sans', sans-serif;
  
    --fs-1: 2.125rem;
    --fs-2: 1.875rem;
    --fs-3: 1.5rem;
    --fs-4: 1.375rem;
    --fs-5: 1.125rem;
    --fs-6: 0.875rem;
    --fs-7: 0.625rem;
  
    --fw-400: 400;
    --fw-600: 600;
  
    /**
     * transition
     */
  
    --transition: 0.5s ease;
  
    /**
     * spacing
     */
  
    --section-padding: 50px;
  
    /**
     * radius
     */
  
    --radius-10: 10px;
    --radius-14: 14px;
    --radius-18: 18px;
  
    /**
     * shadow
     */
  
    --shadow-1: 3px 3px 9px hsla(240, 14%, 69%, 0.2);
    --shadow-2: 3px 3px 9px hsla(204, 92%, 59%, 0.3);
  
  }

  
  /*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  li { list-style: none; }
  
  a { text-decoration: none; }
  
  a,
  img,
  span,
  label,
  input,
  
  
 
  input {
    background: none;
    border: none;
    font: inherit;
  }
  
  
  input { width: 100%; }
  
  ion-icon { pointer-events: none; }



/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

.boat-header-contact { display: none; }

.boat_header {
  background: var(--alice-blue-1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: var(--transition);
  z-index: 4;
}


.boat_header .active { box-shadow: var(--shadow-1); }

.boat_header .boat_container {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boat_header-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.boat_header-actions .boat-btn span { display: none; }

.menu-boat-btn {
  --color: var(--eerie-black);
  --background: var(--white);
  --shadow-2: var(--shadow-1);
  box-shadow: var(--shadow-1);
  
}

.nav-toggle-btn span {
  background: var(--independence);
  width: 22px;
  height: 2px;
  border-radius: 2px;
  margin-block: 8px;
  transform-origin: right;
  transition: var(--transition);
}

.nav-toggle-btn span.two { transform: scaleX(0.7); }

.nav-toggle-btn span.three { transform: scaleX(0.4); }

.nav-toggle-btn:is(:hover, :focus) span { background: var(--carolina-blue); }

.nav-toggle-btn.active span {
  transform: scaleX(1);
  background: var(--carolina-blue);
}

.boat_navbar {
  background: var(--alice-blue-1);
  position: fixed;
  top: 70px;
  bottom: 0;
  right: -260px;
  max-width: 260px;
  width: 100%;
  padding: 20px 25px;
  border: 1px solid var(--white);
  box-shadow: var(--shadow-1);
  visibility: hidden;
  transition: 0.25s cubic-bezier(0.51, 0.03, 0.64, 0.28);
}

.boat_navbar.active {
  visibility: visible;
  transform: translateX(-100%);
  transition: 0.5s cubic-bezier(0.33, 0.85, 0.56, 1.02);
}

.boat-navbar-link {
  color: var(--space-cadet);
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  padding-block: 5px;
  margin-bottom: 15px;
}

.boat-navbar-link:is(:hover, :focus) { color: var(--carolina-blue); }

.boat_overlay {
  position: fixed;
  top: 70px;
  background: hsl(216, 38%, 95%);
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}

.boat_overlay .active {
  opacity: 0.7;
  pointer-events: all;
}


/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

.boat-footer {
    background: var(--alice-blue-4);
    color: var(--independence);
  }
  
  .boat-footer a { color: inherit; }
  
  .boat-footer-top {
    padding-block: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 50px;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .boat-footer-top .boat_logo { margin-bottom: 20px; }
  
  .boat-footer-text {
    font-size: var(--fs-6);
    line-height: 1.8;
  }
  
  .boat-footer-list { font-family: var(--ff-nunito); }
  
  .boat-footer-list:not(:last-of-type) { width: 50%; }
  
  .boat-footer-list:last-of-type {
    width: 100%;
    column-count: 2;
  }
  
  .boat-footer-list-title {
    color: var(--white);
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .boat-footer-link {
    font-size: var(--fs-6);
    padding-block: 6px;
  }
  
  .boat-footer-link:is(:hover, :focus) { color: var(--carolina-bue); }
  
  .boat-footer-list:last-child > li:first-child { column-span: all; }
  
  .boat-footer-bottom {
    background: var(--gradient);
    border: 1px solid var(--white);
    border-radius: var(--radius-18) var(--radius-18) 0 0;
    padding: 20px;
  }
  
  .boat-social-list {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .boat-social-link { font-size: 20px; }
  
  .boat-social-link ion-icon { --ionicon-stroke-width: 40px;
                         color: rgb(62, 93, 65); }
                         
  .boat-social-link i{ --ionicon-stroke-width: 40px;
                        color: rgb(62, 93, 65); } 
  
  .boat-social-link:is(:hover, :focus) { color: var(--carolina-blue); }
  
  .boat-copyright { font-size: var(--fs-6); 
    color: rgb(55, 88, 59);}
  
  .boat-copyright > a { display: inline-block; }
  
  .boat-copyright > a:is(:hover, :focus) { color: var(--carolina-blue); }

  
  /**
 * responsive for large than 580px screen
 */

@media (min-width: 580px) {

    /**
     * REUSED STYLE
     */
  
    .boat_container {
      max-width: 540px;
      margin-inline: auto;
    }
  
  
  
    /**
     * HEADER
     */
  
    .boat_header-actions .boat-btn span {
      display: block;
      font-weight: var(--fw-400);
      padding-inline: 15px;
    }
  
    .boat_header-actions .boat-btn:first-of-type ion-icon { display: none; }

    body {
        overflow-y: scroll; /* Add scrollbar if content overflows vertically */
    }
    .boat-footer {
        position: relative; /* Change footer position to relative */
        z-index: 2; /* Ensure footer appears above content */
    }
    
  
  }

  
  /**
 * responsive for large than 768px screen
 */

@media (min-width: 768px) {

    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 2.625rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .boat_container { max-width: 720px; }
  
  
  
    /**
     * HEADER
     */
  
    .boat_header-actions { gap: 30px; }
  
    .boat-header-contact {
      display: block;
      text-align: center;
    }
  
    .boat-header-contact .boat-contact-link {
      color: var(--space-cadet);
      font-family: var(--ff-nunito);
      font-size: var(--fs-5);
      line-height: 1.3;
    }
  
    .boat-header-contact .boat-contact-link:is(:hover, :focus) { color: var(--carolina-blue); }
  
    .boat-header-contact .boat-contact-time {
      color: var(--eerie-black);
      font-size: var(--fs-7);
    }
/**
   * FOOTER
   */

   .boat-footer-brand { width: 100%; }

   .boat-footer-text { max-width: 400px; }
 
   .boat-footer-bottom {
     display: flex;
     flex-direction: row-reverse;
     justify-content: space-between;
     align-items: center;
     padding-block: 30px;
     box-shadow: var(--shadow-1);
   }
 
   .boat-social-list { margin-bottom: 0; }
 
 }
 
 @media (min-width: 992px) {

  
  
  
    /**
     * FOOTER
     */
  
    .boat-footer-list:not(:last-of-type) { width: 25%; }
  
    .boat-footer-list:last-of-type { width: 50%; }
  
  }


  /**
 * responsive for large than 1200px screen
 */

@media (min-width: 1200px) {

    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 2.875rem;
      --fs-2: 2rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .boat_container { max-width: 1170px; }
  
  
  
  
    /**
     * HEADER
     */
  
    .boat_overlay,
    .nav-toggle-btn { display: none; }
  
    .boat_navbar,
    .boat_navbar.active { all: unset; }
  
    .boat-navbar-link {
      margin-bottom: 0;
      font-weight: var(--fw-600);
    }
  
    .boat_navbar-list {
      display: flex;
      gap: 50px;
    }
  
  
  
    /**
     * HERO
     */
  
    .hero { min-height: 100vh; }
  
    .hero-banner {
      left: auto;
      right: 50px;
      width: 630px;
    }
  
  
  
  
  
    /**
     * SUPER YACHT 
     */
  
    .super-yacht-list { grid-template-columns: repeat(3, minmax(0, 1fr)); }
  
  
  
    /**
     * GET START
     */
  
    .get-start-list { grid-template-columns: repeat(4, 1fr); }
  
  
  
    /**
     * BLOG
     */
  
    .blog .has-scrollbar > li {
      max-width: 340px;
      scroll-snap-align: center;
    }
  
  
  
    /**
     * FOOTER
     */
  
    .boat-footer-brand { width: 33.33%; }
  
    .boat-footer-text { max-width: 35ch; }
  
    .boat-footer-list:not(:last-of-type) { width: 16.66%; }
  
    .boat-footer-list:last-of-type { width: 33.33%; }
  
  }
  
  
  
  /* Styles for laptop devices */
  @media only screen and (min-width: 1024px) {

  
    .btn-submit {
      display: block;
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: var(--carolina-blue);
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease; /* Smooth transition for button background */
    }
  
    .btn-submit:hover {
      background-color: hsl(199, 30%, 39%); /* Darken button background on hover */
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {


  
    .btn-submit {
      display: block;
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: var(--carolina-blue);
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease; /* Smooth transition for button background */
    }
  
    .btn-submit:hover {
      background-color: hsl(199, 30%, 39%); /* Darken button background on hover */
    }
  }


  @media only screen and (max-width: 767px) {

    .btn-submit {
      display: block;
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: var(--carolina-blue);
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease; /* Smooth transition for button background */
    }
  
    .btn-submit:hover {
      background-color: hsl(199, 30%, 39%); /* Darken button background on hover */
    }
    
  }

  .popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    text-align: center;
  }
  
  .popup img {
    max-width: 90%;
    max-height: 90%;
    margin: auto; /* Center horizontally and vertically */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  .card-banner {
    position: relative;
  }
  
  .w-100 {
    width: 100%;
    height: auto;
  }

  .boat-btn {
    position: relative;
    background: var(--background, var(--carolina-blue));
    color: var(--color, var(--white));
    min-width: var(--width, 40px);
    min-height: var(--height, 40px);
    padding: 5px;
    place-items: center;
    border-radius: var(--radius-14);
    font-family: var(--ff-nunito);
    font-size: var(--fs-6);
    font-weight: var(--fw-600);
    overflow: hidden;
}

.boat-product-price p span,
.boat-product-detail ul li span,
.boat-product-detail ul li b,
.boat-product-rating i,
.boat-product-rating span {
  display: inline; /* Ensure all specified elements stay inline */
}

.boat-details {
  text-align: center; /* Centers the text content horizontally */
  margin: 0 auto; /* Centers the .boat-details container itself if it has a fixed width */
}

.boat-details p {
  line-height: 1.6; /* Optional: improve readability with a line height */
}

.boat-details h2 {
  margin-bottom: 10px; /* Add some space below the heading */
}

