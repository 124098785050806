


:root {

  /**
   * colors
   */

  --pale-purple-pantone: hsl(262, 63%, 92%);
  --medium-sea-green: hsl(152, 63%, 43%);
  --lavender-blush: hsl(336, 35%, 92%);
  --carolina-blue: hsl(172, 71%, 25%);
  --columbia-blue: hsl(204, 92%, 90%);
  --alice-blue-1: hsl(216, 38%, 95%);
  --alice-blue-2: hsl(216, 75%, 97%);
  --alice-blue-3: hsl(216, 38%, 95%);
  --alice-blue-4: hsl(172, 71%, 25%);
  --independence: hsl(0, 0%, 100%);
  --deep-cerise: hsl(329, 63%, 52%);
  --eerie-black: hsl(210, 11%, 15%);
  --space-cadet: hsl(240, 22%, 25%);
  --blue-jeans: hsl(204, 80%, 63%);
  --slate-blue: hsl(262, 60%, 57%);
  --beau-blue: hsl(208, 86%, 92%);
  --honey-dew: hsl(152, 48%, 89%);
  --mimi-pink: hsl(329, 63%, 90%);
  --red-salsa: hsl(0, 79%, 63%);
  --sapphire: hsl(211, 100%, 35%);
  --manatee: hsl(219, 14%, 60%);
  --white: hsl(0, 0%, 100%);

  --gradient: linear-gradient(to top, var(--alice-blue-2), var(--alice-blue-3));

  /**
   * typography
   */

  --ff-nunito: 'Nunito', sans-serif;
  --ff-open-sans: 'Open Sans', sans-serif;

  --fs-1: 2.125rem;
  --fs-2: 1.875rem;
  --fs-3: 1.5rem;
  --fs-4: 1.375rem;
  --fs-5: 1.125rem;
  --fs-6: 0.875rem;
  --fs-7: 0.625rem;

  --fw-400: 400;
  --fw-600: 600;

  /**
   * transition
   */

  --transition: 0.5s ease;

  /**
   * spacing
   */

  --section-padding: 50px;

  /**
   * radius
   */

  --radius-10: 10px;
  --radius-14: 14px;
  --radius-18: 18px;

  /**
   * shadow
   */

  --shadow-1: 3px 3px 9px hsla(240, 14%, 69%, 0.2);
  --shadow-2: 3px 3px 9px hsla(204, 92%, 59%, 0.3);

}





/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a { text-decoration: none; }

a,
img,
span,
label,
input,
button,
ion-icon { display: block; }

button,
input {
  background: none;
  border: none;
  font: inherit;
}

button { cursor: pointer; }

input { width: 100%; }

ion-icon { pointer-events: none; }

html {
  font-family: var(--ff-open-sans);
  color: var(--eerie-black);
  font-size: 1rem;
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  background: var(--alice-blue-1);
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 15px;
  height: 10px;
}

::-webkit-scrollbar-track { background: var(--white); }

::-webkit-scrollbar-thumb {
  background: hsla(219, 14%, 60%, 0.3);
  border: 2px solid var(--white);
}

::-webkit-scrollbar-thumb:hover { background: hsla(219, 14%, 60%, 0.5); }





/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 15px; }

button, a { transition: var(--transition); }

.btn {
  position: relative;
  background: var(--background, var(--carolina-blue));
  color: var(--color, var(--white));
  min-width: var(--width, 40px);
  min-height: var(--height, 40px);
  padding: 5px;
  display: grid;
  place-items: center;
  border-radius: var(--radius-14);
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  font-weight: var(--fw-600);
  overflow: hidden;
}

.btn ion-icon {
  font-size: 22px;
  --ionicon-stroke-width: 40px;
}

.btn::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, hsla(0, 0%, 100%, 0.4), transparent);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}

.btn:is(:hover, :focus) { box-shadow: var(--shadow-2); }

.btn:is(:hover, :focus)::before { opacity: 1; }
.section-first{
margin-top: 40px;
}
.section { padding-block: var(--section-padding); }

.h1,
.h2,
.h3 {
  color: var(--space-cadet);
  font-family: var(--ff-nunito);
  line-height: 1.4;
}

.h1 {
  font-size: var(--fs-1);
  text-transform: uppercase;
}

.h2,
.h3 { font-weight: var(--fw-400); }

.h2 { font-size: var(--fs-2); }

.h3 { font-size: var(--fs-4); }

.w-100 { width: 100%; }





/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

.header-contact { display: none; }

.header {
  background: var(--alice-blue-1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: var(--transition);
  z-index: 4;
}

.header.active { box-shadow: var(--shadow-1); }

.header .container {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-actions .btn span { display: none; }

.menu-btn {
  --color: var(--eerie-black);
  --background: var(--white);
  --shadow-2: var(--shadow-1);
  box-shadow: var(--shadow-1);
  
}

.nav-toggle-btn span {
  background: var(--carolina-blue);
  width: 22px;
  height: 2px;
  border-radius: 2px;
  margin-block: 8px;
  transform-origin: right;
  transition: var(--transition);
}

.nav-toggle-btn span.two { transform: scaleX(0.7); }

.nav-toggle-btn span.three { transform: scaleX(0.4); }

.nav-toggle-btn:is(:hover, :focus) span { background: var(--carolina-blue); }

.nav-toggle-btn.active span {
  transform: scaleX(1);
  background: var(--carolina-blue);
}

.navbar {
  background: var(--alice-blue-1);
  position: fixed;
  top: 70px;
  bottom: 0;
  right: -260px;
  max-width: 260px;
  width: 100%;
  padding: 20px 25px;
  border: 1px solid var(--white);
  box-shadow: var(--shadow-1);
  visibility: hidden;
  transition: 0.25s cubic-bezier(0.51, 0.03, 0.64, 0.28);
}

.navbar.active {
  visibility: visible;
  transform: translateX(-100%);
  transition: 0.5s cubic-bezier(0.33, 0.85, 0.56, 1.02);
}

.navbar-link {
  color: var(--space-cadet);
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  padding-block: 5px;
  margin-bottom: 15px;
}

.navbar-link:is(:hover, :focus) { color: var(--carolina-blue); }

.overlay {
  position: fixed;
  top: 70px;
  background: hsl(216, 38%, 95%);
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}

.overlay.active {
  opacity: 0.7;
  pointer-events: all;
}

.one .two .three{
  background-color: black;
}





/*-----------------------------------*\
  #HERO
\*-----------------------------------*/

.hero { padding-top: 120px; }

.hero-title {
  max-width: 20ch;
  margin-bottom: 15px;
}

.hero-text {
  color: var(--space-cadet);
  line-height: 1.8;
  margin-bottom: 30px;
}

.hero-banner { display: none; }



/*-----------------------------------*\
  #SUPER YACHT
\*-----------------------------------*/

.super-yacht .title-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px 15px;
  margin-bottom: 30px;
}

.super-yacht-link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--independence);
  font-size: var(--fs-6);
}

.super-yacht-link span { transition: var(--transition); }

.super-yacht-link:is(:hover, :focus) span { color: var(--space-cadet); }

.super-yacht-link ion-icon {
  margin-top: 3px;
  transition: var(--transition);
}

.super-yacht-link:is(:hover, :focus) ion-icon { color: var(--carolina-blue); }

.super-yacht-list {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 30px;
}

.super-yacht-card {
  background: var(--gradient);
  border: 1px solid var(--white);
  border-radius: var(--radius-18);
  padding: 10px;
  box-shadow: var(--shadow-1);
}

.super-yacht-card .card-banner {
  background: hsla(0, 0%, 0%, 0.2);
  aspect-ratio: 3 / 2;
  border-radius: var(--radius-18);
  overflow: hidden;
}

.super-yacht-card .card-banner > img {
  height: 100%;
  object-fit: cover;
}

.super-yacht-card .card-content { padding: 20px 10px 10px; }

.super-yacht-card .card-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}

.super-yacht-card .card-title { width: calc(100% - 60px); }

.super-yacht-card .card-title > a {
  color: inherit;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.super-yacht-card .card-title > a:is(:hover, :focus) { color: var(--carolina-blue); }

.super-yacht-card .place {
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  font-weight: var(--fw-600);
  padding: 3px 12px;
  border: 2px dashed hsla(204, 91%, 53%, 0.4);
  border-radius: var(--radius-14);
}

.super-yacht-card .card-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
  margin-bottom: 15px;
}

.super-yacht-card .card-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.super-yacht-card .card-list-item ion-icon {
  font-size: 20px;
  color: var(--carolina-blue);
  --ionicon-stroke-width: 38px;
}

.super-yacht-card .card-item-text {
  color: var(--space-cadet);
  font-size: var(--fs-6);
}

.super-yacht-card .card-price-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.super-yacht-card .card-price {
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  color: var(--space-cadet);
}

.super-yacht-card .card-price strong {
  font-size: var(--fs-3);
  font-weight: var(--fw-400);
}

.super-yacht-card .btn:last-child {
  --height: 36px;
  min-width: 100%;
}

.super-yacht-card .fav-btn {
  --background: var(--beau-blue);
  --color: var(--blue-jeans);
  --height: 36px;
  --width: 36px;
  --shadow-2: none;
}

.super-yacht-card .fav-btn ion-icon { font-size: 18px; }

.super-yacht-card .fav-btn:is(:hover, :focus) {
  --background: hsl(153, 84%, 83%);
  --color: var(--alice-blue-4);
}





/*-----------------------------------*\
  #GET START
\*-----------------------------------*/

.get-start .section-title { margin-bottom: 25px; }

.get-start-list {
  display: grid;
  gap: 20px;
}

.get-start-card {
  background: var(--white);
  padding: 20px;
  border-radius: var(--radius-18);
  border: 1px solid var(--white);
  transition: var(--transition);
}

.get-start-card:hover {
  background: var(--alice-blue-2);
  box-shadow: var(--shadow-1);
}

.get-start-card .card-icon {
  background: var(--icon-card-bg, var(--columnia-blue));
  color: var(--icon-card-color, var(--carolina-blue));
  height: 50px;
  width: 50px;
  display: grid;
  place-items: center;
  border-radius: var(--radius-14);
  margin-bottom: 20px;
}

.get-start-card .card-icon ion-icon {
  font-size: 26px;
  --ionicon-stroke-width: 45px;
}

.get-start-card .icon-1 {
  --icon-card-bg: var(--mimi-pink);
  --icon-card-color: var(--deep-cerise);
}

.get-start-card .icon-2 {
  --icon-card-bg: var(--columbia-blue);
  --icon-card-color: var(--carolina-blue);
}

.get-start-card .icon-3 {
  --icon-card-bg: var(--honey-dew);
  --icon-card-color: var(--medium-sea-green);
}

.get-start-card .icon-4 {
  --icon-card-bg: var(--pale-purple-pantone);
  --icon-card-color: var(--slate-blue);
}

.get-start-card .card-title {
  color: var(--space-cadet);
  font-family: var(--ff-nunito);
  font-weight: var(--fw-400);
  margin-bottom: 15px;
}

.get-start-card .card-text { color: var(--carolina-blue); }

.get-start-card .card-link {
  position: relative;
  color: var(--deep-cerise);
  width: max-content;
}

.get-start-card .card-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--deep-cerise);
  transition: var(--transition);
}

.get-start-card .card-link:is(:hover, :focus)::before { width: 100%; }





/*-----------------------------------*\
  #BLOG
\*-----------------------------------*/

.blog .section-title { margin-bottom: 30px; }

.blog-card {
  background: var(--alice-blue-2);
  border-radius: var(--radius-18);
  border: 1px solid var(--white);
  box-shadow: var(--shadow-1);
  overflow: hidden;
}

.blog-card .card-banner {
  aspect-ratio: 3 / 2;
  position: relative;
  overflow: hidden;
}

.blog-card .card-banner a:first-child { height: 100%; }

.blog-card .card-banner img {
  height: 100%;
  object-fit: cover;
}

.blog-card .card-badge {
  position: absolute;
  bottom: 20px;
  left: 20px;
  --height: 30px;
  --width: 92px;
}

.blog-card .card-content { padding: 20px; }

.blog-card .card-title { margin-bottom: 20px; }

.blog-card .card-title > a { color: inherit; }

.blog-card .card-title > a:is(:hover, :focus) { color: var(--carolina-blue); }

.blog-card .card-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-card :is(.publish-date, .comments) {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: var(--fs-6);
  color: var(--space-cadet);
}

.blog-card :is(.publish-date, .comments) ion-icon {
  font-size: 15px;
  --ionicon-stroke-width: 50px;
}

.blog .has-scrollbar {
  display: flex;
  gap: 20px;
  scroll-snap-type: inline mandatory;
  overflow-x: auto;
  padding-bottom: 20px;
}

.blog .has-scrollbar > li {
  flex-shrink: 0;
  max-width: 330px;
  width: 100%;
  scroll-snap-align: start;
}

.has-scrollbar::-webkit-scrollbar-track {
  background: var(--alice-blue-1);
  outline: 2px solid var(--carolina-blue);
  border-radius: 10px;
}

.has-scrollbar::-webkit-scrollbar-thumb {
  border-color: var(--alice-blue-1);
  background: var(--carolina-blue);
  border-radius: 10px;
}

.has-scrollbar::-webkit-scrollbar-button { width: calc(25% - 40px); }


.blog .has-scrollbar {
  display: flex;
  gap: 20px;
  scroll-snap-type: inline mandatory;
  overflow-x: auto;
  padding-bottom: 20px;
}

.blog .has-scrollbar > li {
  flex-shrink: 0;
  max-width: 330px;
  width: 100%;
  scroll-snap-align: start;
}

.has-scrollbar::-webkit-scrollbar {
  height: 18px; /* Increases the height of the horizontal scroll bar */
}

.has-scrollbar::-webkit-scrollbar-thumb {
  height: 16px; /* Matches the height for the thumb part */
  background: var(--carolina-blue);
  border-radius: 10px;
}

.has-scrollbar::-webkit-scrollbar-track {
  background: var(--alice-blue-1);
  border-radius: 10px;
}


/* Mobile styling (one card at a time) */
@media (max-width: 768px) {
  .blog .has-scrollbar {
    flex-direction: column;
    overflow-x: unset;
  }

  .blog .has-scrollbar > li {
    max-width: 100%;
    margin-bottom: 20px;
  }
}


/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

.footer {
  background: var(--alice-blue-4);
  color: var(--independence);
}

.footer a { color: inherit; }

.footer-top {
  padding-block: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 50px;
}

.footer-top .logo { margin-bottom: 20px; }

.footer-text {
  font-size: var(--fs-6);
  line-height: 1.8;
}

.footer-list { font-family: var(--ff-nunito); }

.footer-list:not(:last-of-type) { width: 50%; }

.footer-list:last-of-type {
  width: 100%;
  column-count: 2;
}

.footer-list-title {
  color: var(--white);
  font-weight: bold;
  margin-bottom: 8px;
}

.footer-link {
  font-size: var(--fs-6);
  padding-block: 6px;
}

.footer-link:is(:hover, :focus) { color: var(--carolina-bue); }

.footer-list:last-child > li:first-child { column-span: all; }

.footer-bottom {
  background: var(--gradient);
  border: 1px solid var(--white);
  border-radius: var(--radius-18) var(--radius-18) 0 0;
  padding: 20px;
}

.social-list {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.social-link { font-size: 20px; }

.social-link ion-icon{ --ionicon-stroke-width: 40px;
                       color: rgb(62, 93, 65); }

.social-link i{ --ionicon-stroke-width: 40px;
                       color: rgb(62, 93, 65); }                      

.social-link:is(:hover, :focus) { color: var(--carolina-blue); }

.copyright { font-size: var(--fs-6); 
  color: rgb(55, 88, 59);}

.copyright > a { display: inline-block; }

.copyright > a:is(:hover, :focus) { color: var(--carolina-blue); }





/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for large than 350px screen
 */

@media (min-width: 350px) {

  /**
   * SUPER YACHT
   */

  .super-yacht-card .card-list { grid-template-columns: 1fr 1fr; }

  .super-yacht-card .card-price { margin-right: auto; }

  .super-yacht-card .btn:last-child {
    min-width: max-content;
    padding-inline: 15px;
  }

}





/**
 * responsive for large than 580px screen
 */

@media (min-width: 580px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 540px;
    margin-inline: auto;
  }



  /**
   * HEADER
   */

  .header-actions .btn span {
    display: block;
    font-weight: var(--fw-400);
    padding-inline: 15px;
  }

  .header-actions .btn:first-of-type ion-icon { display: none; }

}




/**
 * responsive for large than 768px screen
 */

@media (min-width: 768px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 2.625rem;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 720px; }



  /**
   * HEADER
   */

  .header-actions { gap: 30px; }

  .header-contact {
    display: block;
    text-align: center;
  }

  .header-contact .contact-link {
    color: var(--space-cadet);
    font-family: var(--ff-nunito);
    font-size: var(--fs-5);
    line-height: 1.3;
  }

  .header-contact .contact-link:is(:hover, :focus) { color: var(--carolina-blue); }

  .header-contact .contact-time {
    color: var(--eerie-black);
    font-size: var(--fs-7);
  }



  /**
   * HERO
   */

  .hero {
    position: relative;
    min-height: 85vh;
    display: flex;
    align-items: center;
    z-index: 1;
  }



  .hero-banner {
    display: block;
    background: url("../images/yacht-banner.webp") no-repeat;
    background-size: cover;
    background-position: left;
    position: absolute;
    top: 100px;
    bottom: 50px;
    left: 65%;
    width: 500px;
    border-radius: 30px;
    z-index: -1;
  }



  /**
   * SUPER YACHT
   */

  .super-yacht-list { grid-template-columns: repeat(2, minmax(0, 1fr)); }



  /**
   * GET START
   */

  .get-start-list { grid-template-columns: 1fr 1fr; }



  /**
   * FOOTER
   */

  .footer-brand { width: 100%; }

  .footer-text { max-width: 400px; }

  .footer-bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-block: 30px;
    box-shadow: var(--shadow-1);
  }

  .social-list { margin-bottom: 0; }

}





/**
 * responsive for large than 992px screen
 */

@media (min-width: 992px) {

  /**
   * REUSED STYLE
   */

  .container { max-width: 960px; }



  /**
   * HERO
   */

  .hero .container { width: 100%; }



  /**
   * BLOG
   */

  .blog .has-scrollbar { padding-bottom: 50px; }

  .blog .has-scrollbar > li { max-width: 450px; }



  /**
   * FOOTER
   */

  .footer-list:not(:last-of-type) { width: 25%; }

  .footer-list:last-of-type { width: 50%; }

}





/**
 * responsive for large than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 2.875rem;
    --fs-2: 2rem;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 1170px; }




  /**
   * HEADER
   */

  .overlay,
  .nav-toggle-btn { display: none; }

  .navbar,
  .navbar.active { all: unset; }

  .navbar-link {
    margin-bottom: 0;
    font-weight: var(--fw-600);
  }

  .navbar-list {
    display: flex;
    gap: 50px;
  }



  /**
   * HERO
   */

  .hero { min-height: 100vh; }

  .hero-banner {
    left: auto;
    right: 50px;
    width: 630px;
  }





  /**
   * SUPER YACHT 
   */

  .super-yacht-list { grid-template-columns: repeat(3, minmax(0, 1fr)); }



  /**
   * GET START
   */

  .get-start-list { grid-template-columns: repeat(4, 1fr); }



  /**
   * BLOG
   */

  .blog .has-scrollbar > li {
    max-width: 340px;
    scroll-snap-align: center;
  }



  /**
   * FOOTER
   */

  .footer-brand { width: 33.33%; }

  .footer-text { max-width: 35ch; }

  .footer-list:not(:last-of-type) { width: 16.66%; }

  .footer-list:last-of-type { width: 33.33%; }

}



/* Styles for laptop devices */
@media only screen and (min-width: 1024px) {
  .booking-form {
    max-width: 400px; /* Adjust based on your design */
    padding: 20px; /* Add padding around the form */
    border: 1px solid #ccc; /* Add a border for visual separation */
    border-radius: 8px; /* Rounded corners for a modern look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    background-color: #f9f9f9; /* Light background color */
  }
  .booking-form h1{
    margin-bottom: 30px;
   font-size: x-large;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333; /* Darken label text color */
  }

  .form-control {
    width: calc(100% - 20px); /* Adjust input width to fit within the form */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease; /* Smooth transition for input borders */
  }

  .form-control:focus {
    border-color: #3498db; /* Change border color on focus for better feedback */
    outline: none; /* Remove default focus outline */
  }

  .btn-submit {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--carolina-blue);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for button background */
  }

  .btn-submit:hover {
    background-color: hsl(199, 30%, 39%); /* Darken button background on hover */
  }
}
  

/* Styles for tablet devices */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .booking-form {
    max-width: 400px; /* Adjust based on your design */
    padding: 15px; /* Add padding around the form */
    border: 1px solid #ccc; /* Add a border for visual separation */
    border-radius: 8px; /* Rounded corners for a modern look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    background-color: #f9f9f9; /* Light background color */
  }

  .form-group {
    margin-bottom: 15px; /* Adjust spacing between form groups */
  }

  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333; /* Darken label text color */
  }

  .form-control {
    width: calc(100% - 20px); /* Adjust input width to fit within the form */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease; /* Smooth transition for input borders */
  }

  .form-control:focus {
    border-color: #3498db; /* Change border color on focus for better feedback */
    outline: none; /* Remove default focus outline */
  }

  .btn-submit {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--carolina-blue);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for button background */
  }

  .btn-submit:hover {
    background-color: hsl(199, 30%, 39%); /* Darken button background on hover */
  }
}


/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .booking-form {
    max-width: 300px; /* Adjust based on your design */
    margin-left: 40px;
    padding: 15px; /* Add padding around the form */
    border: 1px solid #ccc; /* Add a border for visual separation */
    border-radius: 8px; /* Rounded corners for a modern look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    background-color: #f9f9f9; /* Light background color */
  }

  .form-group {
    margin-bottom: 15px; /* Adjust spacing between form groups */
  }

  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333; /* Darken label text color */
  }

  .form-control {
    width: calc(100% - 20px); /* Adjust input width to fit within the form */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease; /* Smooth transition for input borders */
  }

  .form-control:focus {
    border-color: #3498db; /* Change border color on focus for better feedback */
    outline: none; /* Remove default focus outline */
  }

  .btn-submit {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--carolina-blue);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for button background */
  }

  .btn-submit:hover {
    background-color: hsl(199, 30%, 39%); /* Darken button background on hover */
  }
}



.popup {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.popup img {
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close {
  color: #fff;
  position: absolute;
  top: 15px;
  right: 35px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.card-banner {
  position: relative;
}

.w-100 {
  width: 100%;
  height: auto;
}


.location {
  display: flex;
  flex-wrap: wrap;
  gap: 45px;
  justify-content: center;
}

.location-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 350px;
  transition: transform 0.3s ease;
}

.location-card:hover {
  transform: translateY(-10px);
}

.location-card .waterride img {
  width: 100%;
  height: auto;
  display: block;
}

.details {
  padding: 15px;
}

.details h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.details p {
  margin-bottom: 10px;
  color: #555;
}

.details button {
  background-color: var(--carolina-blue);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.details button:hover {
  background-color: var(--carolina-blue);
}

@media (min-width: 768px) {
  .location-card {
      flex: 1 1 calc(-50% - 20px); /* Adjusted to account for gap */
  }
}

@media (min-width: 1024px) {
  .location-card {
      flex: 1 1 calc(33.33% - 20px); /* Adjusted to account for gap */
  }
}


.yacht-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background-color: #f9f9f9;
  overflow: hidden;
}

.content {
  max-width: 600px;
  padding: 20px;
  flex: 1 1 300px;
}

.title {
  font-size: 18px;
  color: #666;
  margin-bottom: 10px;
  animation: fadeInLeft 1s ease-out;
}

.main-title {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
  animation: fadeInLeft 1.2s ease-out;
}

.description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
  animation: fadeInLeft 1.4s ease-out;
}

.features {
  list-style: none;
  padding: 0;
}

.feature-item {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  animation: fadeInLeft 1.6s ease-out;
}

.icon {
  color: var(--carolina-blue);
  margin-right: 10px;
}

.image-container {
  flex: 1 1 300px;
  text-align: center;
  animation: fadeInRight 1.8s ease-out;
}

.yacht-image {
  width: 100%;
  max-width: 700px;
  border-radius: 8px;
}

@keyframes fadeInLeft {
  from {
      opacity: 0;
      transform: translateX(-20px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
      opacity: 0;
      transform: translateX(20px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .yacht-section {
      flex-direction: column;
      text-align: center;
  }

  .content, .image-container {
      flex: 1 1 100%;
  }

  .main-title {
      font-size: 28px;
  }

  .description, .feature-item {
      font-size: 14px;
  }

  .yacht-image {
      max-width: 100%;
  }
}


@media screen and (min-width: 1024px) {
  .yacht-image {
    margin-left: 16%;
}
}




.form-container.unique-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: auto;
  margin-top: 50px; /* Adjust as necessary */
  width: 90%;
  max-width: 400px;
  font-family: Arial, sans-serif;
}

.form-container.unique-form h1 {
  text-align: center;
}

.form-container.unique-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container.unique-form label {
  margin: 10px 0 5px;
  color: #333;
}

.form-container.unique-form input,
.form-container.unique-form select {
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #9c9b9b;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
}

.form-container.unique-form input:focus,
.form-container.unique-form select:focus {
  border-color: var(--carolina-blue);
  outline: none;
}

.form-container.unique-form button {
  padding: 10px 20px;
  background-color: var(--carolina-blue);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.form-container.unique-form button:hover {
  background-color: var(--carolina-blue);
}




.faq-container {
  max-width: 1300px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
}

.faq-section {
  margin-bottom: 30px;
}

.faq-section h2 {
  margin-bottom: 20px;
  color: #555;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.faq-item p {
  font-size: 16px;
  color: #666;
}

.faq-item a {
  color: #007BFF;
  text-decoration: none;
}

.faq-item a:hover {
  text-decoration: underline;
}

body {
  position: relative;
  margin: 0;
}
.scroll-arrows {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure arrows appear above other content */
}
.scroll-arrow {
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 20%;
  transition: background-color 0.3s, transform 0.3s;
}
.scroll-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transform: scale(1.1);
}


.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table h1{
  font-size: x-large;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.styled-table tbody tr:hover a {
  color: #009879;
}

.styled-table a {
  color: inherit;
  text-decoration: none;
}

.styled-table a:hover {
  text-decoration: underline;
}
/* Add this new parent container for the two pricing sections */
.pricing-sections-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px; /* Space between the two tables */
}

/* Make the tables stack vertically on smaller screens */
@media (max-width: 1024px) {
  .pricing-sections-container {
    flex-direction: column;
    align-items: center;
  }
}


.text-success{
  color: #009879;
}
.text-danger{
  color: crimson;
}