.not-found-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: hsl(172, 71%, 25%); /* Dark teal background */
    text-align: center;
    color: white;
    margin-bottom: 0.1%;
  }
  
  .error-code h1 {
    font-size: 10rem;
    font-weight: bold;
    letter-spacing: -5px;
    color: hsl(172, 71%, 50%); /* Softer teal for the 404 */
    text-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
    animation: floating 3s ease-in-out infinite;
  }
  
  .message p {
    font-size: 1.5rem;
    margin: 20px 0;
  }
  
  .go-home {
    margin-top: 8%;
    background-color: hsl(172, 71%, 50%); /* Reddish-orange for CTA button */
    color: white;
    padding: 15px 30px;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .go-home:hover {
    background-color: hsl(172, 71%, 50%); /* Slightly lighter for hover effect */
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  }
  
  /* Floating animation for the 404 number */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .error-code h1 {
      font-size: 7rem;
    }
    
    .message p {
      font-size: 1.2rem;
    }
  
    .go-home {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }