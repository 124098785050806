@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');


/*-----------------------------------*\
  #style.css
\*-----------------------------------*/




/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

    /**
     * colors
     */
  
    --pale-purple-pantone: hsl(262, 63%, 92%);
    --medium-sea-green: hsl(152, 63%, 43%);
    --lavender-blush: hsl(336, 35%, 92%);
    --carolina-blue: hsl(172, 71%, 25%);
    --columbia-blue: hsl(204, 92%, 90%);
    --alice-blue-1: hsl(216, 38%, 95%);
    --alice-blue-2: hsl(216, 75%, 97%);
    --alice-blue-3: hsl(216, 38%, 95%);
    --alice-blue-4: hsl(172, 71%, 25%);
    --independence: hsl(0, 0%, 100%);
    --deep-cerise: hsl(329, 63%, 52%);
    --eerie-black: hsl(210, 11%, 15%);
    --space-cadet: hsl(240, 22%, 25%);
    --blue-jeans: hsl(204, 80%, 63%);
    --slate-blue: hsl(262, 60%, 57%);
    --beau-blue: hsl(208, 86%, 92%);
    --honey-dew: hsl(152, 48%, 89%);
    --mimi-pink: hsl(329, 63%, 90%);
    --red-salsa: hsl(0, 79%, 63%);
    --sapphire: hsl(211, 100%, 35%);
    --manatee: hsl(219, 14%, 60%);
    --white: hsl(0, 0%, 100%);
  
    --gradient: linear-gradient(to top, var(--alice-blue-2), var(--alice-blue-3));
  
    /**
     * typography
     */
  
    --ff-nunito: 'Nunito', sans-serif;
    --ff-open-sans: 'Open Sans', sans-serif;
  
    --fs-1: 2.125rem;
    --fs-2: 1.875rem;
    --fs-3: 1.5rem;
    --fs-4: 1.375rem;
    --fs-5: 1.125rem;
    --fs-6: 0.875rem;
    --fs-7: 0.625rem;
  
    --fw-400: 400;
    --fw-600: 600;
  
    /**
     * transition
     */
  
    --transition: 0.5s ease;
  
    /**
     * spacing
     */
  
    --section-padding: 50px;
  
    /**
     * radius
     */
  
    --radius-10: 10px;
    --radius-14: 14px;
    --radius-18: 18px;
  
    /**
     * shadow
     */
  
    --shadow-1: 3px 3px 9px hsla(240, 14%, 69%, 0.2);
    --shadow-2: 3px 3px 9px hsla(204, 92%, 59%, 0.3);
  
  }

  
  /*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  li { list-style: none; }
  
  a { text-decoration: none; }
  
  a,
  img,
  span,
  label,
  input,
  
  
 
  input {
    background: none;
    border: none;
    font: inherit;
  }
  
  
  input { width: 100%; }
  
  ion-icon { pointer-events: none; }



/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

.header-contact { display: none; }

.header {
  background: var(--alice-blue-1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: var(--transition);
  z-index: 4;
}


.header.active { box-shadow: var(--shadow-1); }

.header .container {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-actions .btn span { display: none; }

.menu-btn {
  --color: var(--eerie-black);
  --background: var(--white);
  --shadow-2: var(--shadow-1);
  box-shadow: var(--shadow-1);
  
}

.nav-toggle-btn span {
  background: var(--independence);
  width: 22px;
  height: 2px;
  border-radius: 2px;
  margin-block: 8px;
  transform-origin: right;
  transition: var(--transition);
}

.nav-toggle-btn span.two { transform: scaleX(0.7); }

.nav-toggle-btn span.three { transform: scaleX(0.4); }

.nav-toggle-btn:is(:hover, :focus) span { background: var(--carolina-blue); }

.nav-toggle-btn.active span {
  transform: scaleX(1);
  background: var(--carolina-blue);
}

.navbar {
  background: var(--alice-blue-1);
  position: fixed;
  top: 70px;
  bottom: 0;
  right: -260px;
  max-width: 260px;
  width: 100%;
  padding: 20px 25px;
  border: 1px solid var(--white);
  box-shadow: var(--shadow-1);
  visibility: hidden;
  transition: 0.25s cubic-bezier(0.51, 0.03, 0.64, 0.28);
}

.navbar.active {
  visibility: visible;
  transform: translateX(-100%);
  transition: 0.5s cubic-bezier(0.33, 0.85, 0.56, 1.02);
}

.navbar-link {
  color: var(--space-cadet);
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  padding-block: 5px;
  margin-bottom: 15px;
}

.navbar-link:is(:hover, :focus) { color: var(--carolina-blue); }

.overlay {
  position: fixed;
  top: 70px;
  background: hsl(216, 38%, 95%);
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}

.overlay.active {
  opacity: 0.7;
  pointer-events: all;
}


/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

.footer {
    background: var(--alice-blue-4);
    color: var(--independence);
  }
  
  .footer a { color: inherit; }
  
  .footer-top {
    padding-block: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 50px;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .footer-top .logo { margin-bottom: 20px; }
  
  .footer-text {
    font-size: var(--fs-6);
    line-height: 1.8;
  }
  
  .footer-list { font-family: var(--ff-nunito); }
  
  .footer-list:not(:last-of-type) { width: 50%; }
  
  .footer-list:last-of-type {
    width: 100%;
    column-count: 2;
  }
  
  .footer-list-title {
    color: var(--white);
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .footer-link {
    font-size: var(--fs-6);
    padding-block: 6px;
  }
  
  .footer-link:is(:hover, :focus) { color: var(--carolina-bue); }
  
  .footer-list:last-child > li:first-child { column-span: all; }
  
  .footer-bottom {
    background: var(--gradient);
    border: 1px solid var(--white);
    border-radius: var(--radius-18) var(--radius-18) 0 0;
    padding: 20px;
  }
  
  .social-list {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .social-link { font-size: 20px; }
  
  .social-link ion-icon { --ionicon-stroke-width: 40px;
                         color: rgb(62, 93, 65); }
                         
  .social-link i{ --ionicon-stroke-width: 40px;
                        color: rgb(62, 93, 65); } 
  
  .social-link:is(:hover, :focus) { color: var(--carolina-blue); }
  
  .copyright { font-size: var(--fs-6); 
    color: rgb(55, 88, 59);}
  
  .copyright > a { display: inline-block; }
  
  .copyright > a:is(:hover, :focus) { color: var(--carolina-blue); }

  
  /**
 * responsive for large than 580px screen
 */

@media (min-width: 580px) {

    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 540px;
      margin-inline: auto;
    }
  
  
  
    /**
     * HEADER
     */
  
    .header-actions .btn span {
      display: block;
      font-weight: var(--fw-400);
      padding-inline: 15px;
    }
  
    .header-actions .btn:first-of-type ion-icon { display: none; }

    body {
        overflow-y: scroll; /* Add scrollbar if content overflows vertically */
    }
    .footer {
        position: relative; /* Change footer position to relative */
        z-index: 2; /* Ensure footer appears above content */
    }
    
  
  }

  
  /**
 * responsive for large than 768px screen
 */

@media (min-width: 768px) {

    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 2.625rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 720px; }
  
  
  
    /**
     * HEADER
     */
  
    .header-actions { gap: 30px; }
  
    .header-contact {
      display: block;
      text-align: center;
    }
  
    .header-contact .contact-link {
      color: var(--space-cadet);
      font-family: var(--ff-nunito);
      font-size: var(--fs-5);
      line-height: 1.3;
    }
  
    .header-contact .contact-link:is(:hover, :focus) { color: var(--carolina-blue); }
  
    .header-contact .contact-time {
      color: var(--eerie-black);
      font-size: var(--fs-7);
    }
/**
   * FOOTER
   */

   .footer-brand { width: 100%; }

   .footer-text { max-width: 400px; }
 
   .footer-bottom {
     display: flex;
     flex-direction: row-reverse;
     justify-content: space-between;
     align-items: center;
     padding-block: 30px;
     box-shadow: var(--shadow-1);
   }
 
   .social-list { margin-bottom: 0; }
 
 }
 
 @media (min-width: 992px) {

  
  
  
    /**
     * FOOTER
     */
  
    .footer-list:not(:last-of-type) { width: 25%; }
  
    .footer-list:last-of-type { width: 50%; }
  
  }


  /**
 * responsive for large than 1200px screen
 */

@media (min-width: 1200px) {

    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 2.875rem;
      --fs-2: 2rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 1170px; }
  
  
  
  
    /**
     * HEADER
     */
  
    .overlay,
    .nav-toggle-btn { display: none; }
  
    .navbar,
    .navbar.active { all: unset; }
  
    .navbar-link {
      margin-bottom: 0;
      font-weight: var(--fw-600);
    }
  
    .navbar-list {
      display: flex;
      gap: 50px;
    }
  
  
  
    /**
     * HERO
     */
  
    .hero { min-height: 100vh; }
  
    .hero-banner {
      left: auto;
      right: 50px;
      width: 630px;
    }
  
  
  
  
  
    /**
     * SUPER YACHT 
     */
  
    .super-yacht-list { grid-template-columns: repeat(3, minmax(0, 1fr)); }
  
  
  
    /**
     * GET START
     */
  
    .get-start-list { grid-template-columns: repeat(4, 1fr); }
  
  
  
    /**
     * BLOG
     */
  
    .blog .has-scrollbar > li {
      max-width: 340px;
      scroll-snap-align: center;
    }
  
  
  
    /**
     * FOOTER
     */
  
    .footer-brand { width: 33.33%; }
  
    .footer-text { max-width: 35ch; }
  
    .footer-list:not(:last-of-type) { width: 16.66%; }
  
    .footer-list:last-of-type { width: 33.33%; }
  
  }
  
  
  
  /* Styles for laptop devices */
  @media only screen and (min-width: 1024px) {

  
    .btn-submit {
      display: block;
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: var(--carolina-blue);
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease; /* Smooth transition for button background */
    }
  
    .btn-submit:hover {
      background-color: hsl(199, 30%, 39%); /* Darken button background on hover */
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {


  
    .btn-submit {
      display: block;
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: var(--carolina-blue);
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease; /* Smooth transition for button background */
    }
  
    .btn-submit:hover {
      background-color: hsl(199, 30%, 39%); /* Darken button background on hover */
    }
  }


  @media only screen and (max-width: 767px) {

    .btn-submit {
      display: block;
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: var(--carolina-blue);
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease; /* Smooth transition for button background */
    }
  
    .btn-submit:hover {
      background-color: hsl(199, 30%, 39%); /* Darken button background on hover */
    }
    .purchase-info{
      margin-left: 20%;
    }
  }

  .popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    text-align: center;
  }
  
  .popup img {
    max-width: 90%;
    max-height: 90%;
    margin: auto; /* Center horizontally and vertically */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  .card-banner {
    position: relative;
  }
  
  .w-100 {
    width: 100%;
    height: auto;
  }

  .btn {
    position: relative;
    background: var(--background, var(--carolina-blue));
    color: var(--color, var(--white));
    min-width: var(--width, 40px);
    min-height: var(--height, 40px);
    padding: 5px;
    place-items: center;
    border-radius: var(--radius-14);
    font-family: var(--ff-nunito);
    font-size: var(--fs-6);
    font-weight: var(--fw-600);
    overflow: hidden;
}


.about-sec{
	display: flex;
	padding: 3rem 0;
	width: 100%;
	justify-content: center;
	background: white;
	margin-top: 8%;
	margin-bottom: 5%;
}
.about-img{
	width: 250px;
	height: 250px;
	margin: 0 3rem;
}
.about-img img{
	height: 100%;
	width: 100%;
}
.about-intro{
	color: black;
	width: 400px;
	height: 250px;
	border-left: 3px solid var(--carolina-blue);
	padding-left: 2rem;
	margin: 0 3rem;
}
.about-intro p{
	font-size: 14px;
	opacity: .7;
}
@media only screen and (max-width: 900px) {
	.about-sec{
		flex-direction: column;
		align-items: center;
	}
	.about-img{
		width: 80%;
	}
	.about-intro{
		width: 100%;
		height: 100%;
		border-top: 3px solid var(--carolina-blue);
		border-left: none;
		padding: 1rem;
		margin-top: 2rem;
		margin-left: 25%;
	}
	
	.about-intro p{
		font-size: 12px;
	}


}

.about-intro span {
  display: inline; /* Ensure all specified elements stay inline */
}
