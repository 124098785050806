.charter-container {
  max-width: 1000px;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.date-picker-box {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.form-control {
  height: 50px;
  border-radius: 5px;
}

.date-picker-btn {
  background-color: white;
  border: 1px solid #ccc;
  color: black;
}

.mb-3 {
  margin-bottom: 1.5rem;

}

.d-flex {
  display: flex;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.input-group-text {
  margin-left: 10px;
}

.align-items-stretch {
  align-items: stretch;
}

/* Existing styles... */
/* Change the background color of the range input (track) */
input[type="range"] {
  -webkit-appearance: none; /* Override default appearance */
  width: 100%; /* Full width */
  height: 8px; /* Height of the track */
  background: #ddd; /* Color of the track */
  border-radius: 5px; /* Round the track */
  outline: none; /* Remove outline */
}

/* Remove focus styles for the range input */
input[type="range"]:focus {
  outline: none; /* Remove outline */
  border: none; /* Remove border */
}

/* Change the thumb (the draggable part) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default appearance */
  appearance: none; /* Override default appearance */
  width: 20px; /* Width of the thumb */
  height: 20px; /* Height of the thumb */
  border-radius: 50%; /* Make it circular */
  background: hsl(172, 71%, 25%); /* Color of the thumb */
  cursor: pointer; /* Change cursor on hover */
}

/* Firefox */
input[type="range"]::-moz-range-thumb {
  width: 20px; /* Width of the thumb */
  height: 20px; /* Height of the thumb */
  border-radius: 50%; /* Make it circular */
  background: hsl(172, 71%, 25%); /* Color of the thumb */
  cursor: pointer; /* Change cursor on hover */
}

/* Edge */
input[type="range"]::-ms-thumb {
  width: 20px; /* Width of the thumb */
  height: 20px; /* Height of the thumb */
  border-radius: 50%; /* Make it circular */
  background: hsl(172, 71%, 25%); /* Color of the thumb */
  cursor: pointer; /* Change cursor on hover */
}




/* Mobile devices (max-width: 767.98px) */
@media (max-width: 767.98px) {
  .charter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .charter-container .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .charter-container .col-md-2, .charter-container .col-md-3 {
    width: 100%;
    margin-bottom: 1rem;
  }

  #online_booking_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .date-picker-btn {
    width: 100%;
  }

  .gift-card {
    width: 100%;
  }

  .input-group {
    display: flex;
    justify-content: center;
  }
}

/* Laptop devices (1024px to 1440px) */
@media (min-width: 1024px) and (max-width: 1440px) {
  .charter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: left;
    max-width: 100%; /* Ensure the container doesn't go too wide */
    margin: 0 auto;
  }

  .charter-container .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #online_booking_form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .charter-container .col-md-2, .charter-container .col-md-3 {
    width: 23%; /* Adjust column width for better alignment on laptops */
    margin-bottom: 1rem;
  }

  .gift-card {
    width: auto;
    margin-left: 15%;
  }

  .date-picker-btn {
    width: auto;
  }

  .input-group-text {
    white-space: nowrap;
  }
  #gift{
    margin-top: 2%;
    margin-left: 2%;
  }
  #col{
    margin-left:2%;
  }
}

/* Larger desktop screens (min-width: 1441px) */
@media (min-width: 1441px) {
  .charter-container {
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 5%;

  }

  .charter-container .col-md-2, .charter-container .col-md-3 {
    width: 20%; /* Tighter columns for larger screens */
  }

  .gift-card, .date-picker-btn {
    width: auto;

  }
  .gift-card{
    margin-left: 35%;
    margin-top: 5%;
  }

  .input-group-text {
    white-space: nowrap;
  }
  #gift{
    margin-top: 2%;
    margin-left: 2%;
  }
  #col{
    margin-left:2%;
  }
}
